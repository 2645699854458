import {
	autoUpdate,
	flip,
	FloatingFocusManager,
	FloatingPortal,
	hide,
	offset,
	Placement,
	shift,
	size,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
	useMergeRefs,
	useRole
} from '@floating-ui/react';
import clsx from 'clsx';
import React from 'react';

interface MenuOptions {
	placement?: Placement;
	initialOpen?: boolean;
	open?: boolean;
	modal?: boolean;
	onOpenChange?: (open: boolean) => void;
	closeOnSelection?: boolean;
	width?: number;
}

const useMenu = ({
	placement = 'bottom-start',
	initialOpen = false,
	open: controlledOpen,
	modal = true,
	onOpenChange: setControlledOpen,
	closeOnSelection = true,
	width
}: MenuOptions) => {
	const [, setMenuItems] = React.useState<any[]>([]);
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

	const open = controlledOpen || uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			size({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${rects.floating.width < 120 ? 120 : rects.floating.width}px`
					});
				}
			}),
			flip({
				fallbackAxisSideDirection: 'end'
			}),
			shift({ padding: 5 }),
			hide()
		]
	});

	const context = data.context;

	const click = useClick(context, {
		enabled: controlledOpen == null
	});

	const dismiss = useDismiss(context);
	const role = useRole(context);

	const interactions = useInteractions([click, dismiss, role]);

	return React.useMemo(
		() => ({
			open,
			setOpen,
			...interactions,
			...data,
			modal,
			closeOnSelection,
			width,
			setMenuItems
		}),
		[open, setOpen, interactions, data, modal, closeOnSelection, width]
	);
};

type ContextType = ReturnType<typeof useMenu> | null;

const MenuContext = React.createContext<ContextType>(null);

export const useMenuContext = () => {
	const context = React.useContext(MenuContext);

	if (context == null) {
		throw new Error('Menu components must be wrapped in <Menu />');
	}

	return context;
};

export function Menu({
	children,
	modal = true,
	...restOptions
}: {
	children: React.ReactNode;
} & MenuOptions) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const menu = useMenu({ modal, ...restOptions });
	return <MenuContext.Provider value={menu}>{children}</MenuContext.Provider>;
}

interface MenuTriggerProps {
	children: React.ReactNode | ((props: boolean) => React.ReactNode);
	asChild?: boolean;
}

export const MenuTrigger = React.forwardRef<HTMLElement, MenuTriggerProps>(function MenuTrigger({ children, asChild = false, ...props }, propRef) {
	const context = useMenuContext();
	const childrenRef = (children as any).ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	// `asChild` allows the user to pass any element as the anchor
	const Element = typeof children === 'function' ? children(context.open) : children;
	if (asChild && React.isValidElement(Element)) {
		return React.cloneElement(
			Element,
			context.getReferenceProps({
				ref,
				...props,
				...Element.props,
				'data-state': context.open ? 'open' : 'closed'
			})
		);
	}

	return (
		<button
			ref={ref}
			// The user can style the trigger based on the state
			data-state={context.open ? 'open' : 'closed'}
			{...context.getReferenceProps(props)}
		>
			{typeof children === 'function' ? children(context.open) : children}
		</button>
	);
});

export const MenuContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function MenuContent(props, propRef) {
	const { context: floatingContext, ...context } = useMenuContext();
	const ref = useMergeRefs([context.refs.setFloating, propRef]);

	return (
		<FloatingPortal>
			{context.open && (
				<FloatingFocusManager visuallyHiddenDismiss context={floatingContext} modal={context.modal}>
					<div
						ref={ref}
						style={{
							...(floatingContext.middlewareData.hide?.referenceHidden && {
								visibility: 'hidden',
								userSelect: 'none'
							}),
							position: context.strategy,
							top: context.y ?? 0,
							left: context.x ?? 0
						}}
						{...context.getFloatingProps(props)}
						className={`shadow relative z-50 flex max-h-60 flex-col space-y-1 overflow-y-auto rounded bg-white p-2 ${
							props.className ? props.className : ''
						}`}
					>
						{props.children}
					</div>
				</FloatingFocusManager>
			)}
		</FloatingPortal>
	);
});

interface MenuItemsProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isActive?: boolean;
	activeClasses?: string;
}

export const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemsProps & React.HTMLProps<HTMLButtonElement>>(function MenuItem(props, propRef) {
	const { setOpen, closeOnSelection } = useMenuContext();

	const { isActive, onClick, className, activeClasses, ...rest } = props;
	return (
		<button
			type="button"
			ref={propRef}
			className={clsx(
				'block w-full rounded px-3 py-1.5 text-left text-sm transition duration-150',
				isActive ? `bg-blue-500 text-white hover:bg-blue-500` : 'bg-white hover:bg-gray-100',
				isActive && activeClasses,
				className
			)}
			{...rest}
			onClick={(e) => {
				if (closeOnSelection) setOpen(false);
				if (onClick) onClick(e);
			}}
		>
			{props.children}
		</button>
	);
});
